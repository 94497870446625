import React, { useEffect, useState } from "react";
import "./rateProfessor.css";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import {
  Autocomplete,
  FormControlLabel,
  RadioGroup,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Modal,
  Button,
} from "@mui/material";
import {
  addDoc,
  collection,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import { useUserAuth } from "../../context/UserAuthContext";

const RateManager = () => {
  const location = useLocation();
  const { user } = useUserAuth();
  const [items, setItems] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState("");
  const [id, setId] = useState(location.state);
  const [rating, setRating] = useState(0);
  const [difficulty, setDifficulty] = useState(0);
  const [workAgain, setWorkAgain] = useState("");
  const [progress, setProgress] = useState("");
  const [guidance, setGuidance] = useState("");
  const [truth, setTruth] = useState("");
  const [grade, setGrade] = useState("");
  const [review, setReview] = useState("");
  const navigate = useNavigate();
  const type = location.state?.type;

  const [isModalOpen, setModalOpen] = useState(false);
  const [isCompanyModalOpen, setCompanyModalOpen] = useState(false);

  // Function to close modal
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // Modal style
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("rateManagerFormData"));
    if (storedData) {
      setCompany(storedData.company);
      setId(storedData.id);
      setRating(storedData.rating);
      setDifficulty(storedData.difficulty);
      setWorkAgain(storedData.workAgain);
      setProgress(storedData.progress);
      setGuidance(storedData.guidance);
      setTruth(storedData.truth);
      setGrade(storedData.grade);
      setReview(storedData.review);
      localStorage.removeItem("rateManagerFormData");
    }

    const fetchData = async () => {
      let list = [];
      let resolvedCollectionName =
        type === "manager"
          ? "Managers"
          : type === "coworker"
          ? "Coworkers"
          : "Companies";

      try {
        const querySnapshot = await getDocs(
          collection(db, resolvedCollectionName)
        );
        querySnapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setItems(list);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [type, isModalOpen]);

  useEffect(() => {
    const fetchCompanies = async () => {
      let list = [];
      try {
        const querySnapshot = await getDocs(collection(db, "Companies"));
        querySnapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setCompanies(list);
      } catch (err) {
        console.log(err);
      }
    };
    fetchCompanies();
  }, []);

  const handleWorkAgain = (event) => {
    setWorkAgain(event.target.value);
  };
  const handleProgress = (event) => {
    setProgress(event.target.value);
  };

  const handleGuidance = (event) => {
    setGuidance(event.target.value);
  };

  const handleTruthful = (event) => {
    setTruth(event.target.value);
  };

  const handleGrade = (event) => {
    setGrade(event.target.value);
  };

  const handleReview = (event) => {
    setReview(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user) {
      localStorage.setItem(
        "rateManagerFormData",
        JSON.stringify({
          company,
          id,
          rating,
          difficulty,
          workAgain,
          progress,
          guidance,
          truth,
          grade,
          review,
        })
      );
      navigate("/login");
      return;
    }

    try {
      const docRef = await addDoc(collection(db, "ManagerRatings"), {
        company,
        rating,
        difficulty,
        workAgain,
        progress,
        guidance,
        truth,
        grade,
        review,
        id,
        author_id: user.uid,
        timestamp: serverTimestamp(),
      });
      console.log("Document written with ID: ", docRef.id);
      alert("Successfully rated");
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddManager = async (e) => {
    e.preventDefault();
    const companyName = e.target[0].value;
    const firstName = e.target[1].value;
    const lastName = e.target[2].value;
    const department = e.target[3].value;
    const socialMediaLink = e.target[4].value;

    var item = {
      company: companyName,
      department: department,
      name: firstName + " " + lastName,
      socialMediaLink: socialMediaLink,
    };

    try {
      const docRef = await addDoc(collection(db, "Managers"), {
        name: firstName + " " + lastName,
        company: companyName,
        department: department,
        socialMediaLink: socialMediaLink,
      });
      console.log("Document written with ID: ", docRef.id);
      alert(firstName + " " + lastName + " added as a new manager.");
      item.id = docRef.id;
      setItems((prevItems) => [...prevItems, item]);
      setId(item.id);
      setModalOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddCompany = async (e) => {
    e.preventDefault();
    const companyName = e.target[0].value;
    const storeNumber = e.target[1].value;
    const address = e.target[2].value;
    const website = e.target[3].value;
    const userEmail = e.target[4].value;

    var item = {
      name: companyName,
      address: address,
      storeNumber: storeNumber,
      website: website,
    };

    try {
      const docRef = await addDoc(collection(db, "Companies"), {
        name: companyName,
        storeNumber: storeNumber,
        address: address,
        website: website,
        userEmail: userEmail,
      });
      console.log("Document written with ID: ", docRef.id);
      alert(companyName + " added as a new company.");
      item.id = docRef.id;
      setCompanies((prevItems) => [...prevItems, item]);
      setCompany(item.name);
      setCompanyModalOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const NoOptionsText = ({ type }) => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        alignItems: "center",
      }}
    >
      <span>{`No option found. Click below button to add new ${type}`}</span>
      <Button
        variant="contained"
        size="small"
        onClick={() => {
          type === "manager" ? setModalOpen(true) : setCompanyModalOpen(true);
        }}
      >
        {`Add New ${type}`}
      </Button>
    </Box>
  );

  return (
    <>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form className="modalAddContainer" onSubmit={handleAddManager}>
            <h1>Add A New Manager</h1>
            <em style={{ width: "300px" }}>
              Please make sure that the manager does not already exist.
            </em>
            <input type="text" required placeholder="Name of Company" />
            <input type="text" required placeholder="Manager's First Name" />
            <input type="text" required placeholder="Manager's Last Name" />
            <input type="text" required placeholder="Department" />
            <input
              type="text"
              required
              placeholder="Social Media link of Manager"
            />
            <button type="submit">Add Manager</button>
          </form>
        </Box>
      </Modal>
      <Modal
        open={isCompanyModalOpen}
        onClose={() => setCompanyModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form className="modalAddContainer" onSubmit={handleAddCompany}>
            <h1>Add A New Company</h1>
            <em style={{ width: "300px" }}>
              Please make sure that the company does not already exist.
            </em>
            <input type="text" required placeholder="Name of Company" />
            <input type="number" required placeholder="Store number or unit" />
            <input type="text" placeholder="Address" />
            <input type="text" required placeholder="Link of Company Website" />
            <input type="email" required placeholder="Company's Email" />
            <button type="submit">Add Company</button>
          </form>
        </Box>
      </Modal>
      <Navbar showSearchBar={true} />
      <form className="rate-professor-container">
        <h1>Rate a manager</h1>
        {type && (
          <div className="rate-cell">
            <b>Enter {type} Name</b>
            <div className="inner-rate-cell">
              <Autocomplete
                sx={{ width: 340 }}
                disablePortal
                id="combo-box-demo"
                options={items}
                getOptionLabel={(option) => option.name}
                value={items.find((item) => item.id === id) || null}
                onChange={(event, value) => setId(value?.id)}
                renderInput={(params) => <TextField {...params} label={type} />}
                noOptionsText={<NoOptionsText type="manager" />}
              />
            </div>
          </div>
        )}
        <div className="rate-cell">
          <b>Please enter company where you worked with this manager</b>
          <div className="inner-rate-cell">
            <Autocomplete
              sx={{ width: 340 }}
              disablePortal
              id="combo-box-demo"
              options={companies}
              getOptionLabel={(option) => option.name}
              value={companies.find((item) => item.name === company) || null}
              onChange={(event, value) => setCompany(value?.name)}
              renderInput={(params) => (
                <TextField {...params} label="Company" />
              )}
              noOptionsText={<NoOptionsText type="company" />}
            />
          </div>
        </div>
        <div className="rate-cell">
          <b>Rate your manager</b>
          <div className="inner-rate-cell">
            <Box
              sx={{
                "& > legend": { mt: 2 },
              }}
            >
              <Rating
                name="simple-controlled"
                size="large"
                value={rating}
                onChange={(event, newValue) => {
                  setRating(newValue);
                }}
              />
            </Box>
          </div>
        </div>
        <div className="rate-cell">
          <b>How difficult was this manager? (1 star for most difficult)</b>
          <div className="inner-rate-cell">
            <Box
              sx={{
                "& > legend": { mt: 2 },
              }}
            >
              <Rating
                name="simple-controlled"
                size="large"
                value={difficulty}
                onChange={(event, newValue) => {
                  setDifficulty(newValue);
                }}
              />
            </Box>
          </div>
        </div>
        <div className="rate-cell">
          <b>Would you work with this manager again?</b>
          <div className="inner-rate-cell">
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              required
              value={workAgain}
              onChange={handleWorkAgain}
            >
              <FormControlLabel
                value="Yes"
                control={<Radio size="large" color="success" />}
                label="Yes"
                labelPlacement="bottom"
              />
              <FormControlLabel
                value="No"
                control={<Radio color="error" size="large" />}
                label="No"
                labelPlacement="bottom"
              />
            </RadioGroup>
          </div>
        </div>
        <div className="rate-cell">
          <b>Did working with this manager progress your career?</b>
          <div className="inner-rate-cell">
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              required
              value={progress}
              onChange={handleProgress}
            >
              <FormControlLabel
                value="Yes"
                control={<Radio size="large" color="success" />}
                label="Yes"
                labelPlacement="bottom"
              />
              <FormControlLabel
                value="No"
                control={<Radio color="error" size="large" />}
                label="No"
                labelPlacement="bottom"
              />
            </RadioGroup>
          </div>
        </div>
        <div className="rate-cell">
          <b>Did this manager give clear instructions or guidance?</b>
          <div className="inner-rate-cell">
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              required
              value={guidance}
              onChange={handleGuidance}
            >
              <FormControlLabel
                value="Yes"
                control={<Radio size="large" color="success" />}
                label="Yes"
                labelPlacement="bottom"
              />
              <FormControlLabel
                value="No"
                control={<Radio color="error" size="large" />}
                label="No"
                labelPlacement="bottom"
              />
            </RadioGroup>
          </div>
        </div>
        <div className="rate-cell">
          <b>Was this manager truthful?</b>
          <div className="inner-rate-cell">
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              required
              value={truth}
              onChange={handleTruthful}
            >
              <FormControlLabel
                value="Yes"
                control={<Radio size="large" color="success" />}
                label="Yes"
                labelPlacement="bottom"
              />
              <FormControlLabel
                value="No"
                control={<Radio color="error" size="large" />}
                label="No"
                labelPlacement="bottom"
              />
            </RadioGroup>
          </div>
        </div>
        <div className="rate-cell">
          <b> What was the result of your annual review?</b>
          <div className="inner-rate-cell">
            <Box>
              <InputLabel id="demo-simple-select-label"></InputLabel>
              <Select
                sx={{ minWidth: 290 }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={grade}
                label="Grade"
                onChange={handleGrade}
              >
                <MenuItem value="Above expectation">Above expectation</MenuItem>
                <MenuItem value="Met expectation">Met expectation</MenuItem>
                <MenuItem value="Below expectation">Below expectation</MenuItem>
                <MenuItem value="Performance improvement plan (PIP)">
                  Performance improvement plan (PIP)
                </MenuItem>
              </Select>
            </Box>
          </div>
        </div>
        <div className="rate-cell" style={{ minHeight: "200px" }}>
          <b>Write a Review</b>
          <div className="inner-rate-cell">
            <textarea
              name="review"
              id=""
              placeholder="Your thoughts on this manager?"
              cols="100"
              rows="9"
              value={review}
              onChange={handleReview}
            />
          </div>
        </div>
        <div className="guidelines-cell">
          <p>
            By clicking the "Submit" button, I acknowledge that I have read and
            agreed to the following Community guidelines.
          </p>
          <p>
            <b>Purpose.</b> To shed some light on a company's and its
            management's culture, leadership styles, and overall work
            environment before a jobseeker even applies.
          </p>
          <br />
          <p>
            <b>Be respectful.</b>
            This means treating others with the same respect that you would want
            to be treated with. Avoid personal attacks, name-calling, and other
            forms of harassment. Remember that the people you are writing about
            are real people with feelings. Even if you had a negative
            experience, try to be respectful of the manager and co-worker.
            Remember that everyone is entitled to their own opinion. Just
            because you didn't like a manager or the company doesn't mean no one
            else will.
          </p>
          <br />
          <p>
            <b>Be honest.</b> Only post reviews based on your personal
            experience; do not post false or misleading information. If you had
            a bad experience, that's fine, but be sure to explain why you had a
            bad experience. Don't just say that the manager, co-worker, and/or
            company was "bad" without giving any specific reasons.
          </p>
          <br />
          <p>
            <b>Be specific.</b> When you write a review, be as specific as
            possible. What did you like or dislike about the manager, co-worker,
            and/or company? What were the manager, co-worker, and/or company
            methods? What was the workload like? The more specific you are, the
            more helpful your review will be to others. Don't just say that the
            manager, co-worker, and/or company was "good" or "bad." Give
            specific examples to support your claims. For example, if you
            thought the manager, co-worker, and/or company were effective, you
            could mention what was so effective.
          </p>
          <br />
          <p>
            <b>Be objective.</b> Try to be as objective as possible in your
            reviews. Avoid letting your personal feelings about the manager,
            co-worker, and/or company cloud your judgment. Remember that others
            may have different preferences than you do what you didn't like
            about a manager, co-worker, and/or company someone else might love.
          </p>
          <br />
          <p>
            <b>Be helpful.</b> The goal of Ratemyo.com is to help others
            (co-workers and employees) make informed decisions about their next
            job or career move. When you write a review, try to be as helpful as
            possible. Share your experiences and insights so that others can
            benefit from your knowledge.
          </p>
          <br />
          <p>
            <b>Describe your experience.</b> What did you like or dislike about
            the manager, co-worker, and/or company? The more details you can
            provide, the more helpful your review will be.
          </p>
          <br />
          <div className="inner-rate-cell">
            <Button variant="contained" onClick={handleSubmit}>
              Submit Rating
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default RateManager;
