import React from "react";
import Navbar from "../../components/navbar/Navbar";
import { Box, Button, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const LeaveReview = () => {
  const navigate = useNavigate();

  const handleSubmit = (type) => (e) => {
    e.preventDefault();
    console.log(type);
    if (type === "manager") {
      navigate("/rateManager", { state: { type } });
    } else if (type === "coworker") {
      navigate("/rateCoworker", { state: { type } });
    } else {
      navigate("/rateCompany", { state: { type } });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        bgcolor: "background.default",
      }}
    >
      <Navbar showSearchBar={true} />
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          py: 4,
          margin: "0rem 1rem",
        }}
      >
        <Typography
          variant="h3"
          sx={{ mb: 4, color: "text.primary", textAlign: "center" }}
        >
          Leave a Review
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: { xs: "1fr", md: "repeat(3, 1fr)" },
            gap: 3,
            width: "100%",
            maxWidth: "lg",
            px: 2,
          }}
        >
          {[
            { type: "manager", color: "primary", title: "Manager" },
            { type: "coworker", color: "secondary", title: "Coworker" },
            { type: "company", color: "success", title: "Company" },
          ].map((item) => (
            <Paper
              key={item.type}
              elevation={3}
              sx={{
                p: 3,
                textAlign: "center",
                transition: "box-shadow 0.3s",
                "&:hover": { boxShadow: 6 },
              }}
            >
              <Typography
                variant="h4"
                sx={{ mb: 2, color: `${item.color}.main` }}
              >
                {item.title}
              </Typography>
              <Typography
                variant="body1"
                sx={{ mb: 2, color: "text.secondary" }}
              >
                Share your experience and help others make informed decisions.
              </Typography>
              <Button
                variant="contained"
                color={item.color}
                onClick={handleSubmit(item.type)}
              >
                Review {item.title}
              </Button>
            </Paper>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default LeaveReview;
