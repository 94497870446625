import React, { useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import { useState } from "react";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import ManagerCard from "./ManagerCard";
import "./topmanagers.css";

const TopManagers = () => {
  const [topManagers, setTopManagers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getTopThreeManagers = async () => {
      try {
        // Step 1: Get all ratings from ManagerRatings collection
        const ratingsSnapshot = await getDocs(collection(db, "ManagerRatings"));
        const ratings = {};

        // Step 2: Aggregate ratings for each manager
        ratingsSnapshot.forEach((doc) => {
          const data = doc.data();
          const managerId = data.id; // assuming there's a field `managerId` in ManagerRatings
          const rating = data.rating;

          if (!ratings[managerId]) {
            ratings[managerId] = { total: 0, count: 0 };
          }
          ratings[managerId].total += rating;
          ratings[managerId].count += 1;
        });

        // Step 3: Calculate average ratings
        const averageRatings = Object.keys(ratings).map((managerId) => {
          const { total, count } = ratings[managerId];
          return { managerId, averageRating: total / count };
        });

        // Step 4: Sort managers by average rating in descending order and get top three
        const topManagers = averageRatings
          .sort((a, b) => b.averageRating - a.averageRating)
          .slice(0, 3);

        // Step 5: Fetch manager details from Managers collection
        const managerPromises = topManagers.map(async ({ managerId }) => {
          const managerDoc = await getDoc(doc(db, "Managers", managerId));
          return {
            id: managerId,
            ...managerDoc.data(),
            averageRating: topManagers.find((m) => m.managerId === managerId)
              .averageRating,
          };
        });

        // Wait for all manager details to be fetched
        const topManagerDetails = await Promise.all(managerPromises);
        setTopManagers(topManagerDetails);
      } catch (error) {
        console.error("Error fetching top managers:", error);
      } finally {
        setLoading(false);
      }
    };

    getTopThreeManagers();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Navbar showSearchBar={true} />
      <h1 style={{ textAlign: "center", margin: "100px 0 50px 0" }}>
        Top Rated Managers
      </h1>
      <div className="top-managers-container">
        {topManagers.map((item) => (
          <div>
            <ManagerCard
              name={item.name}
              company={item.company}
              department={item.department}
              id={item.id}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default TopManagers;
