import React, { useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import {
  AttachMoney,
  BusinessCenter,
  CalendarToday,
  Category,
  LocationOn,
  OpenInNew,
  Work,
} from "@mui/icons-material";

const Jobs = () => {
  const [jobs, setJobs] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [location, setLocation] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchJobs = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `https://data.usajobs.gov/api/search?Keyword=${keyword}&LocationName=${location}&ResultsPerPage=10`,
        {
          headers: {
            "Authorization-Key": process.env.REACT_APP_USA_JOBS_API_KEY,
            Host: "data.usajobs.gov",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch jobs");
      }
      const data = await response.json();
      console.log("Jobs data:", data.SearchResult.SearchResultItems); // Log the jobs data
      setJobs(data.SearchResult.SearchResultItems);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchJobs();
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <>
      <Navbar showLinks={true} />
      {/* <Container maxWidth="md" sx={{ marginTop: "100px" }}> */}
      {/* <Box my={4}>
          <Typography variant="h4" component="h1" gutterBottom>
            USA Jobs Search
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={5}>
                <TextField
                  fullWidth
                  label="Job Keyword"
                  variant="outlined"
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <TextField
                  fullWidth
                  label="Location"
                  variant="outlined"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? <CircularProgress size={24} /> : "Search"}
                </Button>
              </Grid>
            </Grid>
          </form>

          {error && (
            <Typography color="error" variant="body2" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}

          <Box mt={4}>
            <Grid container spacing={3}>
              {jobs.map((job) => (
                <Grid item xs={12} key={job.MatchedObjectId}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6" component="h2">
                        {job.MatchedObjectDescriptor.PositionTitle}
                      </Typography>
                      <Typography color="textSecondary" gutterBottom>
                        {job.MatchedObjectDescriptor.DepartmentName}
                      </Typography>
                      <Typography variant="body2" component="p">
                        Location:{" "}
                        {job.MatchedObjectDescriptor.PositionLocationDisplay}
                      </Typography>
                      <Typography variant="body2" component="p">
                        Salary Range:{" "}
                        {
                          job.MatchedObjectDescriptor.PositionRemuneration[0]
                            .MinimumRange
                        }{" "}
                        -{" "}
                        {
                          job.MatchedObjectDescriptor.PositionRemuneration[0]
                            .MaximumRange
                        }{" "}
                        {
                          job.MatchedObjectDescriptor.PositionRemuneration[0]
                            .RateIntervalCode
                        }
                      </Typography>
                      <Box mt={2}>
                        <a
                          href={job.MatchedObjectDescriptor.ApplyURI[0]}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Job Details
                        </a>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box> */}
      {/* </Container> */}
      <Container maxWidth="lg" sx={{ marginTop: "100px" }}>
        <Box my={4}>
          <Typography variant="h4" component="h1" gutterBottom align="center">
            USA Jobs Search
          </Typography>
          <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={5}>
                  <TextField
                    fullWidth
                    label="Job Keyword"
                    variant="outlined"
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    fullWidth
                    label="Location"
                    variant="outlined"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isLoading}
                    sx={{ height: "56px" }}
                  >
                    {isLoading ? <CircularProgress size={24} /> : "Search"}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>

          {error && (
            <Typography color="error" variant="body2" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}

          <Box mt={4}>
            <Grid container spacing={3}>
              {jobs.map((job) => (
                <Grid item xs={12} sm={6} key={job.MatchedObjectId}>
                  <Card
                    elevation={3}
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      "&:hover": {
                        boxShadow: 6,
                        transform: "translateY(-5px)",
                      },
                      transition: "box-shadow 0.3s, transform 0.3s",
                    }}
                  >
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography
                        variant="h6"
                        component="h2"
                        gutterBottom
                        noWrap
                      >
                        {job.MatchedObjectDescriptor.PositionTitle}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Work fontSize="small" sx={{ mr: 1 }} />
                        {job.MatchedObjectDescriptor.DepartmentName}
                      </Typography>
                      <Divider sx={{ my: 2 }} />
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            variant="body2"
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <LocationOn fontSize="small" sx={{ mr: 1 }} />
                            {
                              job.MatchedObjectDescriptor
                                .PositionLocationDisplay
                            }
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant="body2"
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <AttachMoney fontSize="small" sx={{ mr: 1 }} />
                            {`${job.MatchedObjectDescriptor.PositionRemuneration[0].MinimumRange} - 
                            ${job.MatchedObjectDescriptor.PositionRemuneration[0].MaximumRange} 
                            ${job.MatchedObjectDescriptor.PositionRemuneration[0].RateIntervalCode}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant="body2"
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <CalendarToday fontSize="small" sx={{ mr: 1 }} />
                            Closing Date:{" "}
                            {formatDate(
                              job.MatchedObjectDescriptor.PositionEndDate
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant="body2"
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <Category fontSize="small" sx={{ mr: 1 }} />
                            {job.MatchedObjectDescriptor.JobCategory[0].Name}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Box mt={2}>
                        <Chip
                          icon={<BusinessCenter fontSize="small" />}
                          label={job.MatchedObjectDescriptor.OrganizationName}
                          size="small"
                          sx={{ mr: 1, mb: 1 }}
                        />
                      </Box>
                    </CardContent>
                    <CardActions sx={{ justifyContent: "flex-end", p: 2 }}>
                      <Button
                        variant="contained"
                        size="small"
                        endIcon={<OpenInNew />}
                        href={job.MatchedObjectDescriptor.ApplyURI[0]}
                        target="_blank"
                        rel="noopener noreferrer"
                        // sx={{
                        //   background: theme.palette.primary.main,
                        //   "&:hover": {
                        //     background: theme.palette.primary.dark,
                        //   },
                        // }}
                      >
                        View Details
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Jobs;
