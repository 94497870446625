import * as React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ManagerCard.module.css";

export default function ManagerCard({ id, name, company, department }) {
  const obj = {
    id: id,
    name: name,
    company: company,
    department: department,
  };
  const navigate = useNavigate();

  const handleOnSelect = (item) => {
    navigate("/ManagerResult", { state: item });
  };

  return (
    <div className={styles.card}>
      <div className={styles.cardContent}>
        <h2 className={styles.cardTitle}>{name}</h2>
        <p className={styles.cardSubtitle}>Company: {company}</p>
        <p className={styles.cardBody}>Department: {department}</p>
      </div>
      <div className={styles.cardActions}>
        <button
          className={styles.viewButton}
          onClick={() => handleOnSelect(obj)}
        >
          View Ratings
        </button>
      </div>
    </div>
  );
}
