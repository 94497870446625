import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { Link, useNavigate } from "react-router-dom";
import StaticSection from "../static sections/StaticSection";
import hero_img from "../../assets/main.jpg";
import "./searchProfessor.css";

const InclusiveSearch = () => {
  const [items, setItems] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const collections = [
      { name: "Managers", type: "Manager" },
      { name: "Coworkers", type: "Coworker" },
      { name: "Companies", type: "Company" },
    ];

    const fetchData = async () => {
      try {
        const dataFetchPromises = collections.map((col) =>
          getDocs(collection(db, col.name)).then((querySnapshot) => {
            const docs = querySnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
              type: col.type, // Add the collection type here
            }));
            return docs;
          })
        );

        const results = await Promise.all(dataFetchPromises);
        const combinedList = results.flat(); // Flatten the array of arrays
        setItems(combinedList);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, []);

  const handleOnSelect = (item) => {
    console.log(item); // This is good for debugging, logs the selected item

    switch (item.type) {
      case "Manager":
        navigate("/ManagerResult", { state: item });
        break;
      case "Coworker":
        navigate("/CoworkerResult", { state: item });
        break;
      case "Company":
        navigate("/CompanyResult", { state: item });
        break;
      default:
        console.error("Unknown item type:", item.type);
    }
  };

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: "block", textAlign: "left" }}>
          Name: {item.name}
        </span>
        <span style={{ display: "block", textAlign: "left" }}>
          Type: {item.type}
        </span>
      </>
    );
  };

  return (
    <>
      <section>
        <div className="image_wrapper">
          <img src={hero_img} alt="hero" />
          <div className="overlay overlay_2">
            <h3 style={{ marginBottom: "25px" }}>
              <em>RATEMYO INFORM THE WORLD</em>
            </h3>
            <div style={{ width: 360 }}>
              <ReactSearchAutocomplete
                items={items}
                onSelect={handleOnSelect}
                showNoResults
                maxResults="6"
                showClear
                placeholder="Search Manager, Coworker, or Company"
                formatResult={formatResult}
              />
            </div>
            <p
              style={{ color: "white", marginTop: "10px", fontStyle: "italic" }}
            >
              <Link to="/review" style={{ marginTop: "15px", color: "white" }}>
                <b className="searching-links">Submit Review</b>
              </Link>
            </p>
          </div>
        </div>
        <StaticSection />
      </section>
    </>
  );
};

export default InclusiveSearch;
