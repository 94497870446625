import "./navbar.css";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { useState } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { useUserAuth } from "../../context/UserAuthContext";
import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";

const drawerWidth = 240;

function Navbar(props) {
  const { logOut, user } = useUserAuth();
  const handleLogIn = () => {
    navigate("/login");
  };
  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      console.log(error.message);
    }
  };

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [items, setItems] = useState([]);
  const navigate = useNavigate();

  React.useEffect(() => {
    const collections = [
      { name: "Managers", type: "Manager" },
      { name: "Coworkers", type: "Coworker" },
      { name: "Companies", type: "Company" },
    ];

    const fetchData = async () => {
      try {
        const dataFetchPromises = collections.map((col) =>
          getDocs(collection(db, col.name)).then((querySnapshot) => {
            const docs = querySnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
              type: col.type,
            }));
            return docs;
          })
        );

        const results = await Promise.all(dataFetchPromises);
        const combinedList = results.flat();
        setItems(combinedList);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  const handleOnSelect = (item) => {
    console.log(item);

    switch (item.type) {
      case "Manager":
        navigate("/ManagerResult", { state: item });
        break;
      case "Coworker":
        navigate("/CoworkerResult", { state: item });
        break;
      case "Company":
        navigate("/CompanyResult", { state: item });
        break;
      default:
        console.error("Unknown item type:", item.type);
    }
  };

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: "block", textAlign: "left" }}>
          Name: {item.name}
        </span>
        <span style={{ display: "block", textAlign: "left" }}>
          Type: {item.type}
        </span>
      </>
    );
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const blogsNavigation = () => {
    navigate("/blogs");
    handleDrawerToggle();
  };

  const resumeNavigation = () => {
    navigate("/resumeHelp");
    handleDrawerToggle();
  };

  const jobsNavigation = () => {
    navigate("/jobs");
    handleDrawerToggle();
  };

  const topManagersNavigation = () => {
    navigate("/topManagers");
    handleDrawerToggle();
  };

  const adviceNavigation = () => {
    navigate("/advice");
    handleDrawerToggle();
  };

  const aboutNavigation = () => {
    navigate("/about");
    handleDrawerToggle();
  };

  const contactNavigation = () => {
    navigate("/contactUs");
    handleDrawerToggle();
  };

  const communityNavigation = () => {
    navigate("/communityGuidelines");
    handleDrawerToggle();
  };

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={blogsNavigation}>
            <ListItemText primary="Blogs" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={resumeNavigation}>
            <ListItemText primary="Resume/CV Help" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={jobsNavigation}>
            <ListItemText primary="Jobs" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={adviceNavigation}>
            <ListItemText primary="Advice" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={topManagersNavigation}>
            <ListItemText primary="Top Managers" />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={aboutNavigation}>
            <ListItemText primary="About Us" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={contactNavigation}>
            <ListItemText primary="Contact Us" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={communityNavigation}>
            <ListItemText primary="Community Guidelines" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          {user && (
            <ListItemButton onClick={handleLogout}>
              <Button variant="contained" style={{ width: "100%" }}>
                Log out
              </Button>
            </ListItemButton>
          )}
          {!user && (
            <ListItemButton onClick={handleLogIn}>
              <Button variant="contained" style={{ width: "100%" }}>
                Log In
              </Button>
            </ListItemButton>
          )}
        </ListItem>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div style={{ marginBottom: "60px" }}>
      <Box sx={{ display: "flex" }}>
        <AppBar
          position="fixed"
          sx={{
            width: { xxl: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Toolbar className="nav-bar">
            <Link to="/" style={{ textDecoration: "none", color: "black" }}>
              <h2>RateMyO</h2>
            </Link>
            {props.showLinks && (
              <div className="page-links">
                <ul>
                  <li>
                    <Link to="/blogs">Blogs</Link>
                  </li>
                  <li>
                    <Link to="/resumeHelp">Resume/CV Help</Link>
                  </li>
                  <li>
                    <Link to="/jobs">Jobs</Link>
                  </li>
                  <li>
                    <Link to="/topManagers">Top Managers</Link>
                  </li>
                </ul>
              </div>
            )}
            {props.showSearchBar && (
              <div className="search-area" style={{ zIndex: "100" }}>
                <div style={{ width: 360, marginRight: "10px" }}>
                  <ReactSearchAutocomplete
                    items={items}
                    onSelect={handleOnSelect}
                    showNoResults
                    maxResults="6"
                    showClear
                    placeholder="Search Manager, Coworker, or Company"
                    formatResult={formatResult}
                  />
                </div>
                <Button
                  onClick={() => navigate("/review")}
                  variant="contained"
                  size="small"
                >
                  Submit Review
                </Button>
              </div>
            )}
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { xxl: "none" } }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { xxl: drawerWidth }, flexShrink: { xxl: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "block", xxl: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
    </div>
  );
}

Navbar.propTypes = {
  window: PropTypes.func,
};

export default Navbar;
