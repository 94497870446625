import SearchManager from "../search/SearchManager";
import Contact from "../contact/Contact";
import Navbar from "../navbar/Navbar";
import InclusiveSearch from "../search/InclusiveSearch";

const Home = () => {
  return (
    <>
      <Navbar showLinks={true} showSearchBar={false} />
      <InclusiveSearch />
      <Contact />
    </>
  );
};

export default Home;
