import React, { useEffect, useState } from "react";
import "../rateManager/rateProfessor.css";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import {
  Autocomplete,
  FormControlLabel,
  RadioGroup,
  TextField,
  Modal,
  Button,
} from "@mui/material";
import {
  addDoc,
  collection,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import { useUserAuth } from "../../context/UserAuthContext";

const RateCoworker = () => {
  const location = useLocation();
  const [items, setItems] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [id, setId] = useState(location.state);
  const { user } = useUserAuth();
  const [company, setCompany] = useState("");
  const [dependable, setDependable] = useState(0);
  const [trustworthiness, setTrustworthiness] = useState(0);
  const [decisionMaking, setDecisionMaking] = useState(0);
  const [competency, setCompetency] = useState(0);
  const [dedication, setDedication] = useState(0);
  const [communication, setCommunication] = useState(0);
  const [withOthers, setWithOthers] = useState("");
  const [review, setReview] = useState("");
  const navigate = useNavigate();
  const type = location.state?.type;

  // const [inputValue, setInputValue] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [isCompanyModalOpen, setCompanyModalOpen] = useState(false);

  // Function to close modal
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // Modal style
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("rateCoworkerFormData"));
    if (storedData) {
      setCompany(storedData.company);
      setId(storedData.id);
      setDependable(storedData.dependable);
      setTrustworthiness(storedData.trustworthiness);
      setDecisionMaking(storedData.decisionMaking);
      setCompetency(storedData.competency);
      setDedication(storedData.dedication);
      setCommunication(storedData.communication);
      setWithOthers(storedData.withOthers);
      setReview(storedData.review);
      localStorage.removeItem("rateCoworkerFormData");
    }

    const fetchData = async () => {
      let list = [];
      let resolvedCollectionName =
        type === "manager"
          ? "Managers"
          : type === "coworker"
          ? "Coworkers"
          : "Companies";

      try {
        const querySnapshot = await getDocs(
          collection(db, resolvedCollectionName)
        );
        querySnapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setItems(list);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [type, isModalOpen]);

  useEffect(() => {
    const fetchCompanies = async () => {
      let list = [];
      try {
        const querySnapshot = await getDocs(collection(db, "Companies"));
        querySnapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setCompanies(list);
      } catch (err) {
        console.log(err);
      }
    };
    fetchCompanies();
  }, []);

  const handleWithOthers = (event) => {
    setWithOthers(event.target.value);
  };

  const handleReview = (event) => {
    setReview(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user) {
      localStorage.setItem(
        "rateCoworkerFormData",
        JSON.stringify({
          company,
          id,
          dependable,
          trustworthiness,
          decisionMaking,
          competency,
          dedication,
          communication,
          withOthers,
          review,
        })
      );
      navigate("/login");
      return;
    }

    try {
      const docRef = await addDoc(collection(db, "CoworkerRatings"), {
        company,
        dependable,
        trustworthiness,
        decisionMaking,
        competency,
        dedication,
        communication,
        withOthers,
        review,
        id,
        author_id: user.uid,
        timestamp: serverTimestamp(),
      });
      console.log("Document written with ID: ", docRef.id);
      alert("Successfully rated");
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddCoworker = async (e) => {
    e.preventDefault();
    const companyName = e.target[0].value;
    const firstName = e.target[1].value;
    const lastName = e.target[2].value;
    const department = e.target[3].value;
    const socialMediaLink = e.target[4].value;

    var item = {
      company: companyName,
      department: department,
      name: firstName + " " + lastName,
      socialMediaLink: socialMediaLink,
    };

    try {
      const docRef = await addDoc(collection(db, "Coworkers"), {
        name: firstName + " " + lastName,
        company: companyName,
        department: department,
        socialMediaLink: socialMediaLink,
      });
      console.log("Document written with ID: ", docRef.id);
      alert(firstName + " " + lastName + " added as a new coworker.");
      item.id = docRef.id;
      setItems((prevItems) => [...prevItems, item]);
      setId(item.id);
      setModalOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddCompany = async (e) => {
    e.preventDefault();
    const companyName = e.target[0].value;
    const storeNumber = e.target[1].value;
    const address = e.target[2].value;
    const website = e.target[3].value;
    const userEmail = e.target[4].value;

    var item = {
      name: companyName,
      address: address,
      storeNumber: storeNumber,
      website: website,
    };

    try {
      const docRef = await addDoc(collection(db, "Companies"), {
        name: companyName,
        storeNumber: storeNumber,
        address: address,
        website: website,
        userEmail: userEmail,
      });
      console.log("Document written with ID: ", docRef.id);
      alert(companyName + " added as a new company.");
      item.id = docRef.id;
      setCompanies((prevItems) => [...prevItems, item]);
      setCompany(item.name);
      setCompanyModalOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const NoOptionsText = ({ type }) => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        alignItems: "center",
      }}
    >
      <span>{`No option found. Click below button to add new ${type}`}</span>
      <Button
        variant="contained"
        size="small"
        onClick={() => {
          type === "coworker" ? setModalOpen(true) : setCompanyModalOpen(true);
        }}
      >
        {`Add New ${type}`}
      </Button>
    </Box>
  );

  return (
    <>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form className="modalAddContainer" onSubmit={handleAddCoworker}>
            <h1>Add A Coworker</h1>
            <em style={{ width: "300px" }}>
              Please make sure that the coworker does not already exist.
            </em>
            <input type="text" required placeholder="Name of Company" />
            <input type="text" required placeholder="Coworker's First Name" />
            <input type="text" required placeholder="Coworker's Last Name" />
            <input type="text" required placeholder="Department" />
            <input type="url" placeholder="Social Media link of Coworker" />
            <button type="submit">Add Coworker</button>
          </form>
        </Box>
      </Modal>
      <Modal
        open={isCompanyModalOpen}
        onClose={() => setCompanyModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form className="modalAddContainer" onSubmit={handleAddCompany}>
            <h1>Add A New Company</h1>
            <em style={{ width: "300px" }}>
              Please make sure that the company does not already exist.
            </em>
            <input type="text" required placeholder="Name of Company" />
            <input type="number" required placeholder="Store number or unit" />
            <input type="text" placeholder="Address" />
            <input type="text" required placeholder="Link of Company Website" />
            <input type="email" required placeholder="Company's Email" />
            <button type="submit">Add Company</button>
          </form>
        </Box>
      </Modal>
      <Navbar showSearchBar={true} />
      <form className="rate-professor-container">
        <h1>Rate a coworker</h1>
        {type && (
          <div className="rate-cell">
            <b>Enter {type} name</b>
            <div className="inner-rate-cell">
              <Autocomplete
                sx={{ width: 340 }}
                disablePortal
                id="combo-box-demo"
                options={items}
                getOptionLabel={(option) => option.name}
                onChange={(event, value) => setId(value?.id)}
                renderInput={(params) => <TextField {...params} label={type} />}
                value={items.find((item) => item.id === id) || null}
                noOptionsText={<NoOptionsText type="coworker" />}
              />
            </div>
          </div>
        )}
        <div className="rate-cell">
          <b>Please enter company where you worked with this coworker</b>
          <div className="inner-rate-cell">
            <Autocomplete
              sx={{ width: 340 }}
              disablePortal
              id="combo-box-demo"
              options={companies}
              getOptionLabel={(option) => option.name}
              value={companies.find((item) => item.name === company) || null}
              onChange={(event, value) => setCompany(value?.name)}
              renderInput={(params) => (
                <TextField {...params} label="Company" />
              )}
              noOptionsText={<NoOptionsText type="company" />}
            />
          </div>
        </div>
        <div className="rate-cell">
          <b>How dependable is he/she? (1 star for Least dependable)</b>
          <div className="inner-rate-cell">
            <Box
              sx={{
                "& > legend": { mt: 2 },
              }}
            >
              <Rating
                name="simple-controlled"
                size="large"
                value={dependable}
                onChange={(event, newValue) => {
                  setDependable(newValue);
                }}
              />
            </Box>
          </div>
        </div>
        <div className="rate-cell">
          <b>
            How would you rate him/her on trustworthiness? (1 star for least
            trustworthy)
          </b>
          <div className="inner-rate-cell">
            <Box
              sx={{
                "& > legend": { mt: 2 },
              }}
            >
              <Rating
                name="simple-controlled"
                size="large"
                value={trustworthiness}
                onChange={(event, newValue) => {
                  setTrustworthiness(newValue);
                }}
              />
            </Box>
          </div>
        </div>
        <div className="rate-cell">
          <b>Does he/she get along well with others?</b>
          <div className="inner-rate-cell">
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              required
              value={withOthers}
              onChange={handleWithOthers}
            >
              <FormControlLabel
                value="Yes"
                control={<Radio size="large" color="success" />}
                label="Yes"
                labelPlacement="bottom"
              />
              <FormControlLabel
                value="No"
                control={<Radio color="error" size="large" />}
                label="No"
                labelPlacement="bottom"
              />
            </RadioGroup>
          </div>
        </div>
        <div className="rate-cell">
          <b>
            How would you rate his/her decision-making skills? (1 star for poor
            decision making skills)
          </b>
          <div className="inner-rate-cell">
            <Box
              sx={{
                "& > legend": { mt: 2 },
              }}
            >
              <Rating
                name="simple-controlled"
                size="large"
                value={decisionMaking}
                onChange={(event, newValue) => {
                  setDecisionMaking(newValue);
                }}
              />
            </Box>
          </div>
        </div>
        <div className="rate-cell">
          <b>
            How would you rate his/her competency? (1 star for least competent)
          </b>
          <div className="inner-rate-cell">
            <Box
              sx={{
                "& > legend": { mt: 2 },
              }}
            >
              <Rating
                name="simple-controlled"
                size="large"
                value={competency}
                onChange={(event, newValue) => {
                  setCompetency(newValue);
                }}
              />
            </Box>
          </div>
        </div>
        <div className="rate-cell">
          <b>
            How dedicated is he/she to his/her work? (1 star for least
            dedicated)
          </b>
          <div className="inner-rate-cell">
            <Box
              sx={{
                "& > legend": { mt: 2 },
              }}
            >
              <Rating
                name="simple-controlled"
                size="large"
                value={dedication}
                onChange={(event, newValue) => {
                  setDedication(newValue);
                }}
              />
            </Box>
          </div>
        </div>
        <div className="rate-cell">
          <b>
            How effective are his/her communication skills? (1 star for poor
            communication skills)
          </b>
          <div className="inner-rate-cell">
            <Box
              sx={{
                "& > legend": { mt: 2 },
              }}
            >
              <Rating
                name="simple-controlled"
                size="large"
                value={communication}
                onChange={(event, newValue) => {
                  setCommunication(newValue);
                }}
              />
            </Box>
          </div>
        </div>

        <div className="rate-cell" style={{ minHeight: "200px" }}>
          <b>Write a Review</b>
          <div className="inner-rate-cell">
            <textarea
              name="review"
              id=""
              placeholder="Your thoughts on this coworker?"
              cols="100"
              rows="9"
              value={review}
              onChange={handleReview}
            />
          </div>
        </div>
        <div className="guidelines-cell">
          <p>
            By clicking the "Submit" button, I acknowledge that I have read and
            agreed to the following Community guidelines.
          </p>
          <p>
            <b>Purpose.</b>
             To shed some light on a company's and its management's culture,
            leadership styles, and overall work environment before a jobseeker
            even applies.
          </p>
          <br />
          <p>
            <b>Be respectful.</b>
            This means treating others with the same respect that you would want
            to be treated with. Avoid personal attacks, name-calling, and other
            forms of harassment. Remember that the people you are writing about
            are real people with feelings. Even if you had a negative
            experience, try to be respectful of the manager and co-worker.
            Remember that everyone is entitled to their own opinion. Just
            because you didn't like a manager or the company doesn't mean no one
            else will.
          </p>
          <br />
          <p>
            <b>Be honest.</b> Only post reviews based on your personal
            experience; do not post false or misleading information. If you had
            a bad experience, that's fine, but be sure to explain why you had a
            bad experience. Don't just say that the manager, co-worker, and/or
            company was "bad" without giving any specific reasons.
          </p>
          <br />
          <p>
            <b>Be specific.</b> When you write a review, be as specific as
            possible. What did you like or dislike about the manager, co-worker,
            and/or company? What were the manager, co-worker, and/or company
            methods? What was the workload like? The more specific you are, the
            more helpful your review will be to others. Don't just say that the
            manager, co-worker, and/or company was "good" or "bad." Give
            specific examples to support your claims. For example, if you
            thought the manager, co-worker, and/or company were effective, you
            could mention what was so effective.
          </p>
          <br />
          <p>
            <b>Be objective.</b> Try to be as objective as possible in your
            reviews. Avoid letting your personal feelings about the manager,
            co-worker, and/or company cloud your judgment. Remember that others
            may have different preferences than you do what you didn't like
            about a manager, co-worker, and/or company someone else might love.
          </p>
          <br />
          <p>
            <b>Be helpful.</b> The goal of Ratemyo.com is to help others
            (co-workers and employees) make informed decisions about their next
            job or career move. When you write a review, try to be as helpful as
            possible. Share your experiences and insights so that others can
            benefit from your knowledge.
          </p>
          <br />
          <p>
            <b>Describe your experience.</b> What did you like or dislike about
            the manager, co-worker, and/or company? The more details you can
            provide, the more helpful your review will be.
          </p>
          <br />
          <div className="inner-rate-cell">
            <Button variant="contained" onClick={handleSubmit}>
              Submit Rating
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default RateCoworker;
