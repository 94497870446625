import React, { useEffect, useState } from "react";
import "./rateSchool.css";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import { Autocomplete, TextField, Modal, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addDoc,
  collection,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../firebase";
import Navbar from "../navbar/Navbar";
import { useUserAuth } from "../../context/UserAuthContext";

const RateCompany = () => {
  const locationn = useLocation();
  const [items, setItems] = useState([]);
  const [id, setId] = useState(locationn.state);
  const { user } = useUserAuth();
  const [reputation, setReputation] = useState(0);
  const [location, setLocation] = useState(0);
  const [opportunities, setOpportunities] = useState(0);
  const [facilities, setFacilities] = useState(0);
  const [internet, setInternet] = useState(0);
  const [management, setManagement] = useState(0);
  const [clubs, setClubs] = useState(0);
  const [social, setSocial] = useState(0);
  const [happiness, setHappiness] = useState(0);
  const [safety, setSafety] = useState(0);
  const [review, setReview] = useState("");
  const type = locationn.state?.type;
  const navigate = useNavigate();

  const [isModalOpen, setModalOpen] = useState(false);

  // Function to close modal
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // Modal style
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("rateCompanyFormData"));
    if (storedData) {
      setReputation(storedData.reputation);
      setLocation(storedData.location);
      setOpportunities(storedData.opportunities);
      setFacilities(storedData.facilities);
      setInternet(storedData.internet);
      setManagement(storedData.management);
      setClubs(storedData.clubs);
      setSocial(storedData.social);
      setHappiness(storedData.happiness);
      setSafety(storedData.safety);
      setReview(storedData.review);
      setId(storedData.id);
      localStorage.removeItem("rateCompanyFormData");
    }

    const fetchData = async () => {
      let list = [];
      let resolvedCollectionName = "Companies";

      try {
        const querySnapshot = await getDocs(
          collection(db, resolvedCollectionName)
        );
        querySnapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setItems(list);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [type, isModalOpen]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user) {
      localStorage.setItem(
        "rateCompanyFormData",
        JSON.stringify({
          reputation,
          location,
          opportunities,
          facilities,
          internet,
          management,
          clubs,
          social,
          happiness,
          safety,
          review,
          id,
        })
      );
      navigate("/login");
      return;
    }

    try {
      const docRef = await addDoc(collection(db, "CompanyRatings"), {
        reputation,
        location,
        opportunities,
        facilities,
        internet,
        management,
        clubs,
        social,
        happiness,
        safety,
        review,
        id,
        author_id: user.uid,
        timestamp: serverTimestamp(),
      });
      console.log("Document written with ID: ", docRef.id);
      alert("Successfully rated");
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddCompany = async (e) => {
    e.preventDefault();
    const companyName = e.target[0].value;
    const storeNumber = e.target[1].value;
    const address = e.target[2].value;
    const website = e.target[3].value;
    const userEmail = e.target[4].value;

    var item = {
      name: companyName,
      address: address,
      storeNumber: storeNumber,
      website: website,
    };

    try {
      const docRef = await addDoc(collection(db, "Companies"), {
        name: companyName,
        storeNumber: storeNumber,
        address: address,
        website: website,
        userEmail: userEmail,
      });
      console.log("Document written with ID: ", docRef.id);
      alert(companyName + " added as a new company.");
      item.id = docRef.id;
      setItems((prevItems) => [...prevItems, item]);
      setId(item.id);
      setModalOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const NoOptionsText = ({ type }) => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        alignItems: "center",
      }}
    >
      <span>{`No option found. Click below button to add new ${type}`}</span>
      <Button
        variant="contained"
        size="small"
        onClick={() => setModalOpen(true)}
      >
        {`Add New ${type}`}
      </Button>
    </Box>
  );

  return (
    <>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form className="modalAddContainer" onSubmit={handleAddCompany}>
            <h1>Add a Company</h1>
            <em style={{ width: "300px" }}>
              Please make sure that the company does not already exist.
            </em>
            <input type="text" required placeholder="Name of Company" />
            <input type="number" required placeholder="Store number or unit" />
            <input type="text" placeholder="Address" />
            <input type="text" required placeholder="Link of Company Website" />
            <input type="email" required placeholder="Company's Email" />
            <button type="submit">Add Company</button>
          </form>
        </Box>
      </Modal>
      <Navbar showSearchBar={true} />
      <form className="rate-school-container">
        <h1>Rate the Company</h1>
        {type && (
          <div className="rate-cell">
            <b>Enter {type} name</b>
            <div className="inner-rate-cell">
              <Autocomplete
                sx={{ width: 340 }}
                disablePortal
                id="combo-box-demo"
                options={items}
                getOptionLabel={(option) => option.name}
                onChange={(event, value) => setId(value?.id)}
                renderInput={(params) => <TextField {...params} label={type} />}
                value={items.find((item) => item.id === id) || null}
                noOptionsText={<NoOptionsText type="company" />}
              />
            </div>
          </div>
        )}
        <div className="rate-cell">
          <b>Reputation (1 star for least reputable)</b>
          <div className="inner-rate-cell">
            <Box
              sx={{
                "& > legend": { mt: 2 },
              }}
            >
              <Rating
                name="simple-controlled"
                size="large"
                value={reputation}
                onChange={(event, newValue) => {
                  setReputation(newValue);
                }}
              />
            </Box>
          </div>
        </div>
        <div className="rate-cell">
          <b>Location (1 star for least desirable area)</b>
          <div className="inner-rate-cell">
            <Box
              sx={{
                "& > legend": { mt: 2 },
              }}
            >
              <Rating
                name="simple-controlled"
                size="large"
                value={location}
                onChange={(event, newValue) => {
                  setLocation(newValue);
                }}
              />
            </Box>
          </div>
        </div>
        <div className="rate-cell">
          <b>Opportunities (1 star for least personal growth opportunities)</b>
          <div className="inner-rate-cell">
            <Box
              sx={{
                "& > legend": { mt: 2 },
              }}
            >
              <Rating
                name="simple-controlled"
                size="large"
                value={opportunities}
                onChange={(event, newValue) => {
                  setOpportunities(newValue);
                }}
              />
            </Box>
          </div>
        </div>
        <div className="rate-cell">
          <b>
            Facilities and common areas (1 star for least functional facility)
          </b>
          <div className="inner-rate-cell">
            <Box
              sx={{
                "& > legend": { mt: 2 },
              }}
            >
              <Rating
                name="simple-controlled"
                size="large"
                value={facilities}
                onChange={(event, newValue) => {
                  setFacilities(newValue);
                }}
              />
            </Box>
          </div>
        </div>
        <div className="rate-cell">
          <b>Internet (1 star for worst internet)</b>
          <div className="inner-rate-cell">
            <Box
              sx={{
                "& > legend": { mt: 2 },
              }}
            >
              <Rating
                name="simple-controlled"
                size="large"
                value={internet}
                onChange={(event, newValue) => {
                  setInternet(newValue);
                }}
              />
            </Box>
          </div>
        </div>
        <div className="rate-cell">
          <b>Management (1 star for terrible management)</b>
          <div className="inner-rate-cell">
            <Box
              sx={{
                "& > legend": { mt: 2 },
              }}
            >
              <Rating
                name="simple-controlled"
                size="large"
                value={management}
                onChange={(event, newValue) => {
                  setManagement(newValue);
                }}
              />
            </Box>
          </div>
        </div>
        <div className="rate-cell">
          <b>Clubs (1 star for non existent club)</b>
          <div className="inner-rate-cell">
            <Box
              sx={{
                "& > legend": { mt: 2 },
              }}
            >
              <Rating
                name="simple-controlled"
                size="large"
                value={clubs}
                onChange={(event, newValue) => {
                  setClubs(newValue);
                }}
              />
            </Box>
          </div>
        </div>
        <div className="rate-cell">
          <b>Social (1 star for least social)</b>
          <div className="inner-rate-cell">
            <Box
              sx={{
                "& > legend": { mt: 2 },
              }}
            >
              <Rating
                name="simple-controlled"
                size="large"
                value={social}
                onChange={(event, newValue) => {
                  setSocial(newValue);
                }}
              />
            </Box>
          </div>
        </div>
        <div className="rate-cell">
          <b>Happiness (1 star for least collective happiness)</b>
          <div className="inner-rate-cell">
            <Box
              sx={{
                "& > legend": { mt: 2 },
              }}
            >
              <Rating
                name="simple-controlled"
                size="large"
                value={happiness}
                onChange={(event, newValue) => {
                  setHappiness(newValue);
                }}
              />
            </Box>
          </div>
        </div>
        <div className="rate-cell">
          <b>Safety (1 star for dangerous working environment)</b>
          <div className="inner-rate-cell">
            <Box
              sx={{
                "& > legend": { mt: 2 },
              }}
            >
              <Rating
                name="simple-controlled"
                size="large"
                value={safety}
                onChange={(event, newValue) => {
                  setSafety(newValue);
                }}
              />
            </Box>
          </div>
        </div>
        <div className="rate-cell" style={{ minHeight: "100px" }}>
          <b>Review</b>
          <div className="inner-rate-cell">
            <Box
              sx={{ display: "flex", justifyContent: "center", width: "100%" }}
            >
              <TextField
                id="outlined-multiline-static"
                sx={{ width: "85%" }}
                multiline
                rows={2}
                placeholder="What do you want potential employees to know about this company?"
                value={review}
                onChange={(e) => {
                  setReview(e.target.value);
                }}
              />
            </Box>
          </div>
        </div>
        <div className="guidelines-cell">
          <p>
            By clicking the "Submit" button, I acknowledge that I have read and
            agreed to the following Community guidelines.
          </p>
          <p>
            <b>Purpose.</b>
            To shed some light on a company's and its management's culture,
            leadership styles, and overall work environment before a jobseeker
            even applies.
          </p>
          <br />
          <p>
            <b>Be respectful.</b>
            This means treating others with the same respect that you would want
            to be treated with. Avoid personal attacks, name-calling, and other
            forms of harassment. Remember that the people you are writing about
            are real people with feelings. Even if you had a negative
            experience, try to be respectful of the manager and co-worker.
            Remember that everyone is entitled to their own opinion. Just
            because you didn't like a manager or the company doesn't mean no one
            else will.
          </p>
          <br />
          <p>
            <b>Be honest.</b> Only post reviews based on your personal
            experience; do not post false or misleading information. If you had
            a bad experience, that's fine, but be sure to explain why you had a
            bad experience. Don't just say that the manager, co-worker, and/or
            company was "bad" without giving any specific reasons.
          </p>
          <br />
          <p>
            <b>Be specific.</b> When you write a review, be as specific as
            possible. What did you like or dislike about the manager, co-worker,
            and/or company? What were the manager, co-worker, and/or company
            methods? What was the workload like? The more specific you are, the
            more helpful your review will be to others. Don't just say that the
            manager, co-worker, and/or company was "good" or "bad." Give
            specific examples to support your claims. For example, if you
            thought the manager, co-worker, and/or company were effective, you
            could mention what was so effective.
          </p>
          <br />
          <p>
            <b>Be objective.</b> Try to be as objective as possible in your
            reviews. Avoid letting your personal feelings about the manager,
            co-worker, and/or company cloud your judgment. Remember that others
            may have different preferences than you do what you didn't like
            about a manager, co-worker, and/or company someone else might love.
          </p>
          <br />
          <p>
            <b>Be helpful.</b> The goal of Ratemyo.com is to help others
            (co-workers and employees) make informed decisions about their next
            job or career move. When you write a review, try to be as helpful as
            possible. Share your experiences and insights so that others can
            benefit from your knowledge.
          </p>
          <br />
          <p>
            <b>Describe your experience.</b> What did you like or dislike about
            the manager, co-worker, and/or company? The more details you can
            provide, the more helpful your review will be.
          </p>
          <br />
          <div className="inner-rate-cell">
            <Button variant="contained" onClick={handleSubmit}>
              Submit Rating
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default RateCompany;
