import "./App.css";
import Home from "./components/Home/Home";
import { Routes, Route } from "react-router-dom";
import Footer from "./components/footer/Footer";
import ProfessorPersonReview from "./components/personReview/ProfessorPersonReview";
import AddCompany from "./components/add/AddCompany";
import AddManager from "./components/add/AddManager";
import SearchCompany from "./components/search/SearchCompany";
import RateCompany from "./components/rateCompany/RateCompany";
import CompanyResult from "./components/rateCompany/CompanyResult";
import RateManager from "./components/rateManager/RateManager";
import ManagerResult from "./components/rateManager/ManagerResult";
import RateCoworker from "./components/rateCoworker/RateCoworker";
import AddCoworker from "./components/add/AddCoworker";
import CoworkerResult from "./components/rateCoworker/CoworkerResult";
import SearchCoworker from "./components/search/SearchCoworker";
import About from "./pages/About/About";
import Advice from "./pages/Advice/Advice";
import CommunityGuidelines from "./pages/CommunityGuidelines/CommunityGuidlines";
import ContactUs from "./pages/ContactUs/ContactUs";
import Jobs from "./pages/Jobs/Jobs";
import ResumeHelp from "./pages/ResumeHelp/ResumeHelp";
import TopManagers from "./pages/TopManagers/TopManagers";
import PrivacyPolicy from "./pages/privacyPolicy/PrivacyPolicy";
import Copyright from "./pages/copyright/Copyright";
import Login from "./pages/Auth/Login";
import SignUp from "./pages/Auth/SignUp";
import {
  UserAuthContextProvider,
  useUserAuth,
} from "./context/UserAuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
// import ForgetPassword from "./pages/Auth/ForgetPassword";
import Blogs from "./pages/Blog/Blogs";
import BlogForm from "./pages/Blog/BlogForm";
import SingleBlogPage from "./pages/Blog/SingleBlogPage";
import AdminProtection from "./components/AdminProtection";
import CookiePolicy from "./pages/CookiePolicy/CookiePolicy";
import TermOfUse from "./pages/TermOfUse/TermOfUse";
import SubmitReview from "./pages/SubmitReview/SubmitReview";
import LeaveReview from "./pages/SubmitReview/LeaveReview";

function App() {
  return (
    <div className="App">
      <UserAuthContextProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/review" element={<LeaveReview />} />
          <Route path="/searchCompany" element={<SearchCompany />} />
          <Route path="/searchCoworker" element={<SearchCoworker />} />
          <Route
            path="/addCompany"
            element={
              <ProtectedRoute>
                <AddCompany />
              </ProtectedRoute>
            }
          />
          <Route
            path="/addManager"
            element={
              <ProtectedRoute>
                <AddManager />
              </ProtectedRoute>
            }
          />
          <Route
            path="/addCoworker"
            element={
              <ProtectedRoute>
                <AddCoworker />
              </ProtectedRoute>
            }
          />
          <Route path="/rateCoworker" element={<RateCoworker />} />

          <Route path="/rateManager" element={<RateManager />} />

          <Route path="/rateCompany" element={<RateCompany />} />
          <Route path="/managerResult" element={<ManagerResult />} />
          <Route path="/coworkerResult" element={<CoworkerResult />} />
          <Route path="/companyResult" element={<CompanyResult />} />
          <Route path="/about" element={<About />} />
          <Route path="/advice" element={<Advice />} />
          <Route
            path="/communityGuidelines"
            element={<CommunityGuidelines />}
          />
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/resumeHelp" element={<ResumeHelp />} />
          <Route path="/topManagers" element={<TopManagers />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/cookiePolicy" element={<CookiePolicy />} />
          <Route path="/termOfUse" element={<TermOfUse />} />
          <Route path="/copyright" element={<Copyright />} />
          <Route path="/login" element={<Login />} />
          <Route path="/submitReview" element={<SubmitReview />} />
          {/* <Route path="/signup" element={<SignUp />} /> */}
          {/* <Route path="/forgetPassword" element={<ForgetPassword />} /> */}
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs/:id" element={<SingleBlogPage />} />
          <Route
            path="/blogs/new"
            element={
              <AdminProtection>
                <BlogForm />
              </AdminProtection>
            }
          />
          <Route
            path="/managerPersonReview"
            element={<ProfessorPersonReview />}
          />
        </Routes>
      </UserAuthContextProvider>
      <Footer />
    </div>
  );
}

export default App;
